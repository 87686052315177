/**
 * Type: ページ
 * What: お問い合わせフォームページ
 */
import React, { useEffect, useState } from 'react';
import { navigate, graphql, Link } from 'gatsby';
import axios from 'axios';
import { scroller } from 'react-scroll';
import { useForm } from 'react-hook-form';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import '../../sass/components/_form.scss';
import { Button } from '../../components/Btn';

const pageTitle = 'お問い合わせフォーム';
const pageSlug = 'inquiry';

export const query = graphql`
  {
    site(siteMetadata: {}) {
      siteMetadata {
        endPoint
      }
    }
  }
`;

// Data Props Template
const useFormPage = ({ data }) => {
  const {
    register, handleSubmit, errors, reset, getValues,
  } = useForm();
  const [Token, setToken] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const hideConfirmation = () => setIsConfirmationVisible(false);
  const onSubmitData = () => setIsConfirmationVisible(true);
  const [Formdata, setFormdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const APIURL = data.site.siteMetadata.endPoint;
  const formValidate = `${APIURL}/form_validate`;
  const formSubmit = `${APIURL}/form_submit`;
  const formToken = `${APIURL}/form_token`;

  // エラーメッセージにスクロール
  const scrollToTarget = () => {
    scroller.scrollTo('scrollTarget', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeOutQuint',
    });
  };

  // 初回アクセス時にトークン取得
  const tokenFetch = () => {
    axios.post(formToken, { timeout: 20000 })
      .then((res) => {
        if (res.data.errors.length === 0) { // エラーメッセージがあるかどうか 配列が空なら成功
          setToken(res.data.token);
        } else {
          setErrorMessage(res.data.errors);
        }
      })
      .catch((err) => {
        // サーバーエラー
        setErrorMessage(['サーバーエラーが発生しました。']);
      });
  };

  // バリデーション
  const validationPost = () => {
    // setOpen(false);
    setSubmit(true);
    const values = getValues();
    values.token = Token;

    axios.post(formValidate, values, { timeout: 20000 })
      .then((res) => {
        if (res.data.errors.length === 0) { // エラーメッセージがあるかどうか 配列が空なら成功
          confirmTable();
          setErrorMessage(false);
          setTimeout(() => {
            setOpen(true);
            setSubmit(false);
          }, 300);
        } else {
          hideConfirmation();
          scrollToTarget();
          setSubmit(false);
          setErrorMessage(res.data.errors);
        }
      })
      .catch((err) => {
        // サーバーエラー
        setSubmit(false);
        setErrorMessage(['サーバーエラーが発生しました。']);
        // setErrorMessage(err.response.data.message);
      });
  };

  // フォーム送信
  const dataPost = () => {
    const values = getValues();
    values.token = Token;
    setSubmit(true);

    axios.post(formSubmit, values, { timeout: 20000 })
      .then((res) => {
        // console.log(res);
        if (res.data.errors.length === 0) { // エラーメッセージがあるかどうか 配列が空なら成功
          setOpen(false);
          setSubmit(false);
          setTimeout(() => {
            navigate( // 完了ページへ
              '/inquiry/completed/',
              { replace: false },
            );
          }, 600);
        } else {
          hideConfirmation();
          scrollToTarget();
          setSubmit(false);
          setErrorMessage(res.data.errors);
        }
      })
      .catch((err) => {
        hideConfirmation();
        scrollToTarget();
        setSubmit(false);
        setErrorMessage(['サーバーエラーが発生しました。']);
      });
  };

  // モーダル非表示
  const modalClose = () => {
    setOpen(false);
    setTimeout(() => {
      hideConfirmation();
    }, 600);
  };

  // 確認画面のテーブルを自動で組み立て
  const confirmTable = () => {
    const labels = document.querySelectorAll('form .label');
    const control = document.querySelectorAll('form .control');
    const temp = [];

    for (let i = 0; labels.length > i; i++) {
      const label = labels[i];
      const text = label.firstChild.data;

      let { value } = control[i].firstChild;
      value = value || control[i].firstChild.firstChild.value || '';

      temp.push(
        <tr key={i}>
          <th>
            {text}
          </th>
          <td className={`value${i}`} wovn-ignore="true">{value}</td>
        </tr>,
      );
    }

    setFormdata(temp);
  };

  useEffect(() => {
    tokenFetch();
  }, [tokenFetch]);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} />
      <BreadcrumbComponent
        hierarchy={2}
        bread2title={pageTitle}
      />
      <section className="section">
        <div className="container">
          <h3 className="title is-3">
            {pageTitle}
          </h3>
          <p className="notification is-info is-light sub-desc">
            迷惑メール対策等をされている場合は「@softbankhawks.co.jp」のドメインを受信できるように設定のうえ、
            <br />
            お問い合わせください。
          </p>
          <div className="inner-slim">
            { errorMessage && (
            <div className="notification is-danger">
              {Object.keys(errorMessage).map((key) => (
                <p className="error-list" key={key}>
                  {errorMessage[key]}
                </p>
              ))}
            </div>
            ) }
            {isConfirmationVisible && Token && (
            <>
              <div className={`confirmation ${open ? 'open' : ''}`}>
                <div className="confirmation-inner">
                  <h3 className="title is-3">内容を確認</h3>
                  <div className="contactBox">

                    <table className="table is-striped is-bordered is-fullwidth">
                      <tbody>
                        {Formdata}
                      </tbody>
                    </table>
                    <p className="has-text-align-center mb20">
                      上記の内容でよろしければ、下記個人情報の取扱いについてご同意の上、送信ボタンを押して下さい。
                      <br />
                      個人情報の取り扱いは
                      <a href="https://www.softbankhawks.co.jp/company/privacy.html" target="_blank" rel="noopener noreferrer">こちら</a>
                      からご確認いただけます。
                    </p>
                    <div className="btn-group">
                      <div className="buttons control">
                        <button
                          type="button"
                          onClick={modalClose}// クリックでstateをクリアし、入力内容確認画面コンポーネントを非表示にする
                          className="btn-def reverse"
                        >
                          <span>閉じる</span>
                        </button>
                        <button
                          type="submit"
                          className={`btn-def submit ${submit && 'spin'} `}
                          onClick={dataPost}
                        >
                          <span>内容を送信</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Confirmation// 入力内容確認画面コンポーネント
                values={getValues()}
                hideConfirmation={hideConfirmation}
                isConfirmationVisible={isConfirmationVisible}
                token={Token}
              /> */}
            </>
            )}

            <form onSubmit={handleSubmit(onSubmitData)} name="contactForm">

              <div className="field">
                <label className="label" htmlFor="email">
                  メールアドレス
                  {' '}
                  <span className="tag is-rounded is-danger">
                    必須
                  </span>
                </label>
                <div className="control has-icons-left has-icons-right" wovn-ignore="true">
                  <input
                    className={errors.email ? 'input error' : 'input'}
                    type="email"
                    placeholder="メールアドレス"
                    name="email"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                <p className="help">半角（ハイフン無し）で入力ください。</p>
                {errors.email && <p className="help is-danger">{errors.email.message}</p>}

              </div>
              <div className="field">
                <label className="label" htmlFor="email_confirmation">
                  メールアドレス（確認用）
                  {' '}
                  <span className="tag is-rounded is-danger">
                    必須
                  </span>
                </label>
                <div className="control has-icons-left has-icons-right" wovn-ignore="true">
                  <input
                    className={errors.email_confirmation ? 'input error' : 'input'}
                    type="email"
                    placeholder="メールアドレス（確認用）"
                    name="email_confirmation"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                {errors.email_confirmation && <p className="help is-danger">{errors.email_confirmation.message}</p>}
              </div>
              <div className="field">
                <label className="label" htmlFor="Name">
                  氏名（フリガナ）
                  {' '}
                  <span className="tag is-rounded is-danger">
                    必須
                  </span>
                </label>
                <div className="control is-expanded has-icons-left has-icons-right" wovn-ignore="true">
                  <input
                    className={errors.name ? 'input error' : 'input'}
                    type="text"
                    placeholder="氏名（フリガナ）"
                    name="name"
                    id="Name"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user" />
                  </span>
                </div>
                {errors.name && <p className="help is-danger">{errors.name.message}</p>}
              </div>
              <div className="field">
                <label className="label" htmlFor="Telephone">
                  電話番号
                  {' '}
                  <span className="tag is-rounded is-danger">
                    必須
                  </span>
                </label>
                <div className="control has-icons-left has-icons-right" wovn-ignore="true">
                  <input
                    className={errors.telephone ? 'input error' : 'input'}
                    type="tel"
                    placeholder="電話番号"
                    name="telephone"
                    id="Telephone"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-phone-square" />
                  </span>
                </div>
                <p className="help">半角（ハイフン無し）で入力ください。</p>
                {errors.telephone && <p className="help is-danger">{errors.telephone.message}</p>}
              </div>
              <div className="field">
                <label className="label" htmlFor="title">
                  お問い合わせ件名
                  {' '}
                  <span className="tag is-rounded is-danger">
                    必須
                  </span>
                </label>
                <div className="control">
                  <div
                    className="select"
                  >
                    <select
                      name="title"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option value="">---------ご選択ください---------</option>
                      <option value="絶景3兄弟（すべZO／つりZO／のぼZO）">絶景3兄弟 SMBC日興証券（すべZO／つりZO／のぼZO）</option>
                      <option value="V-World AREA">V-World AREA</option>
                      <option value="チームラボフォレスト">チームラボフォレスト 福岡 – SBI証券</option>
                      <option value="王貞治ベースボールミュージアム">王貞治ベースボールミュージアム</option>
                      <option value="MLBカフェ">MLBカフェ</option>
                      <option value="イベントホール">イベントホール</option>
                      <option value="その他">その他</option>
                    </select>
                  </div>
                </div>
                {errors.title && <p className="help is-danger">{errors.title.message}</p>}
              </div>
              <div className="field mb40">
                <label className="label" htmlFor="message">
                  お問い合わせ内容
                  {' '}
                  <span className="tag is-rounded is-danger">
                    必須
                  </span>
                </label>
                <div className="control" wovn-ignore="true">
                  <textarea
                    className={errors.message ? 'textarea is-multiple error' : 'textarea'}
                    placeholder="2000文字以内"
                    name="message"
                    ref={register({
                      required: '必須項目です',
                    })}
                    maxLength="2000"
                  />
                </div>
                {errors.message && <p className="help is-danger">{errors.message.message}</p>}
              </div>
              <div className="field">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn-def reverse"
                    onClick={reset}
                  >
                    <span>クリア</span>
                  </button>
                  <button
                    type="submit"
                    className={`btn-def submit ${submit ? 'spin' : ''}`}
                    onClick={validationPost}
                  >
                    <span>進む</span>
                  </button>
                </div>
              </div>

            </form>
          </div>
          <div className="btn-wrap mt-5">
            <Button
              href="/"
            >
              トップへ戻る
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default useFormPage;
